<template>
  <div class="home">
    <div class="wd-login">
      <div class="sys_title">幼儿园用餐管理系统</div>
      <el-input placeholder="输入您的手机号" v-model="u_phone">
        <template slot="prepend">手机号</template>
      </el-input>
      <el-input placeholder="请输入密码" v-model="u_pass" show-password style="margin:10px 0 0 0">
        <template slot="prepend">密码</template>
      </el-input>
      <el-row style="margin-top:15px">
        <el-col :span="5" class="fixla">登陆身份：</el-col>
        <el-col :span="19">
          <el-select v-model="u_login_type" style="width:100%">
            <el-option
            v-for="item in login_type_list"
            :key="item.value"
            :label="item.label"
            :value="item.value" >
            </el-option>
          </el-select>
          <!-- <el-radio-group v-model="u_login_type">
            <el-radio :label="1">幼儿园</el-radio>
            <el-radio :label="2">供应商</el-radio>
            <el-radio :label="3">管理员</el-radio>
          </el-radio-group> -->
        </el-col>
      </el-row>
      <!-- <div style="margin:6px 0 15px 0;text-align: right;">
        <el-button type="text" size="mini" style="color:#b5a16b"  disabled>忘记密码？</el-button>
      </div> -->
      <el-button type="primary" style="margin:15px 0 6px 0;width:100%" @click="handleWDLogin">登录</el-button>
      <div class="copy_right">v2.8</div>
    </div>
    <div class="login_page_info"><a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2024036318号-1</a>&nbsp;&nbsp;&nbsp;&nbsp;copyright &copy; yyvvi.com</div>
  </div>
</template>

<script>
// @ is an alias to /src
import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";
import * as MN from '@/api/common'
export default {
  name: 'loginView',
  data(){
    return{
      u_login_type:0,
      login_type_list:[
        {value:0,label: '请选择'},
        {value:1,label: '幼儿园'},
        {value:2,label: '供应商'},
        {value:3,label: '管理员'},
      ],
      u_phone: '',
      u_pass: '',
    }
  },
  components: {
		CommonNav,
		CommonHeader
	},
  methods:{
    handleWDLogin(){
      if ( !this.u_login_type || this.u_login_type == 0 ){
        this.$message({
          message: '请选择登陆身份',
          type: 'warning'
        });
        return
      }
      if ( !this.u_phone || !this.u_pass ){
        this.$message({
          message: '请输入账号名和密码',
          type: 'warning'
        });
        return
      }
      let post={
        u_login_type:this.u_login_type,
				u_phone:this.u_phone,
        u_pass:this.u_pass
			}
      let me = this;
      MN.checkLogin(post).then(res => {

        if (  res.status == 'ban_user'){
          me.$message({
            message: '您的账号已停用',
            type: 'warning'
          });
          return
        }

        if (  res.status == 'empty_user'){
          me.$message({
            message: '没有您这个账号，请检查是否选错了登陆类型',
            type: 'warning'
          });
          return
        }
        
				if( res.user_type == 'admin_user' || res.user_type == 'supplier_user' || res.user_type == 'staff_user' ){
          sessionStorage.setItem('kindergarten_meals_session',res.user_uuid)
          sessionStorage.setItem('kindergarten_meals_usertype',res.user_type)
          if (res.user_type == 'supplier_user'){
            me.$router.push({
              path: "/order",
            });
            return
          }
          me.$router.push({
            path: "/menu",
          });
          return
        }else{
          me.$message({
            message: '密码或账号名错误',
            type: 'warning'
          });
          return
        }
			})

    }
  }
  
}
</script>
<style scoped>
.fixla{
  font-size: 14px;
  color: #9a9a9a;
  padding-top: 10px;
  text-align: right;
}
.home{
  position: fixed;
  width: 100%;
  height: 100%;
  background:url('~@/assets/bg1.jpg') no-repeat top #cccccc;
  background-size: 120% 120%;
/*  
  background-color: #EFEFEF; */
}
.sys_title{
  position: absolute;
  font-size: 31px;
  font-weight: lighter;
  color: #b0b1b5;
  left: 0;
  top: -46px;
  width: 100%;
  text-align: center;
}
.wd-login{
  width: 350px;
  padding: 50px;
  /* border: 1px solid #e6e6e6; */
  background-color: #ffffff;
  opacity: 0.98;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 200px;
  position: relative;
  box-shadow: 4px 6px 48px #b3b3b3;
}
.copy_right{
  position: absolute; 
  right: 50px;
  bottom: 10px;
  color: #b0b0b0;
  font-size: 12px;
}
.login_page_info a{
  color: #6b6b6b;
  text-decoration: none;
   font-size: 13px;
}
.login_page_info{
  color: #6b6b6b;
  position: fixed;
  font-size: 13px;
  bottom:30px;
  width: 100%;
  text-align:center
}
</style>
