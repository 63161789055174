import request from '@/utils/request'
import {
  requestUrl
} from './requestUrl.js'



export function setChangeKindergartenStatus(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeKindergartenStatus',
    method: 'POST',
    data:query
  })
}



export function setDelDelKCompany(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelDelKCompany',
    method: 'POST',
    data:query
  })
}



export function saveNewKindergartenCompany(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/saveNewKindergartenCompany',
    method: 'POST',
    data:query
  })
}


export function setNewDishDose(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/setNewDishDose',
    method: 'POST',
    data:query
  })
}


export function getDishDataDetail(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/getDishDataDetail',
    method: 'POST',
    data:query
  })
}

export function getMessageList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/',
    url:requestUrl.baseUrl + '/kindergarten/index/getMessageList',
    method: 'POST',
    data:query
  })
}

export function getHandleBatchSaveDish(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getHandleBatchSaveDish',
    method: 'POST',
    data:query
  })
}


export function setSaveNewSubQuestion(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewSubQuestion',
    method: 'POST',
    data:query
  })
}

export function setSaveNewQuestion(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewQuestion',
    method: 'POST',
    data:query
  })
}

export function questionDataList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/questionDataList',
    method: 'POST',
    data:query
  })
}


export function getHandleBatchSaveFood (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getHandleBatchSaveFood',
    method: 'POST',
    data:query
  })
}

export function getHandleSaveEditMenuTemplate (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getHandleSaveEditMenuTemplate',
    method: 'POST',
    data:query
  })
}

export function getMenuTemplateDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getMenuTemplateDetail',
    method: 'POST',
    data:query
  })
}

export function setSaveEditDish(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveEditDish',
    method: 'POST',
    data:query
  })
}


export function setChangeFoodStatus(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeFoodStatus',
    method: 'POST',
    data:query
  })
}



export function setChangeDishStatus(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeDishStatus',
    method: 'POST',
    data:query
  })
}


export function searchMenuData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/searchMenuData',
    method: 'POST',
    data:query
  })
}

export function saveMenuToTemplate(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/saveMenuToTemplate',
    method: 'POST',
    data:query
  })
}

export function saveEditMenuToTemplate(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/saveEditMenuToTemplate',
    method: 'POST',
    data:query
  })
}

export function handleDeleteTemplate(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/handleDeleteTemplate',
    method: 'POST',
    data:query
  })
}


export function getTemplateList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getTemplateList',
    method: 'POST',
    data:query
  })
}

export function setAddMenuByTemplate(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setAddMenuByTemplate',
    method: 'POST',
    data:query
  })
}


export function getTemplateTableData(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getTemplateTableData',
    method: 'POST',
    data:query
  })
}

export function setSaveNewKindergarten(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewKindergarten',
    method: 'POST',
    data:query
  })
}

export function setSaveNewAdmin(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewAdmin',
    method: 'POST',
    data:query
  })
}


export function setChangeUserStatus(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeUserStatus',
    method: 'POST',
    data:query
  })
}



export function setSaveNewSupplier (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewSupplier',
    method: 'POST',
    data:query
  })
}


export function setOrderConfirm (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setOrderConfirm',
    method: 'POST',
    data:query
  })
}


export function kindergartenLoginUser (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/kindergartenLoginUser',
    method: 'POST',
    data:query
  })
}


export function getOrderDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getOrderDetail',
    method: 'POST',
    data:query
  })
}


export function handleDeleteFood (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/handleDeleteFood',
    method: 'POST',
    data:query
  })
}

export function getSaveMenuOrder(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getSaveMenuOrder',
    method: 'POST',
    data:query
  })
}


export function getOrderList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getOrderList',
    method: 'POST',
    data:query
  })
}

export function supplierDataList(query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/supplierDataList',
    method: 'POST',
    data:query
  })
}


export function getMenuDoseTemplate (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getMenuDoseTemplate',
    method: 'POST',
    data:query
  })
}

export function handleDeleteMenu (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/handleDeleteMenu',
    method: 'POST',
    data:query
  })
}



export function getHandleSaveEditMenu (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getHandleSaveEditMenu',
    method: 'POST',
    data:query
  })
}

export function getActivateFoodList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getActivateFoodList',
    method: 'POST',
    data:query
  })
}

export function getSingleMenuDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getSingleMenuDetail',
    method: 'POST',
    data:query
  })
}

export function getKindergartenList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getKindergartenList',
    method: 'POST',
    data:query
  })
}


export function getActivateTypeList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getActivateTypeList',
    method: 'POST',
    data:query
  })
}

export function setAddNewType (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setAddNewType',
    method: 'POST',
    data:query
  })
}


export function setDelAdmin (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelAdmin',
    method: 'POST',
    data:query
  })
}

export function setDelSupplier (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelSupplier',
    method: 'POST',
    data:query
  })
}

export function setDelKindergarten (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelKindergarten',
    method: 'POST',
    data:query
  })
}


export function setChangeDataStatus (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setChangeDataStatus',
    method: 'POST',
    data:query
  })
}


export function setDelTypeData (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setDelTypeData',
    method: 'POST',
    data:query
  })
}

export function getMenuTypeDataList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getMenuTypeDataList',
    method: 'POST',
    data:query
  })
}

export function getUserDataList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getUserDataList',
    method: 'POST',
    data:query
  })
}

export function searchDishData (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/searchDishData',
    method: 'POST',
    data:query
  })
}

export function setSaveNewDish (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewDish',
    method: 'POST',
    data:query
  })
}


export function getFoodListData (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getFoodListData',
    method: 'POST',
    data:query
  })
}

export function searchFoodData (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/searchFoodData',
    method: 'POST',
    data:query
  })
}


export function setSaveNewFood (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveNewFood',
    method: 'POST',
    data:query
  })
}


export function setSaveEditFood (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/setSaveEditFood',
    method: 'POST',
    data:query
  })
}


export function getSingleCaseAllGrade (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getSingleCaseAllGrade',
    method: 'POST',
    data:query
  })
}


//保存新菜谱
export function getMenuDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getMenuDetail',
    method: 'POST',
    data:query
  })
}


//保存新菜谱
export function getHandleSaveMenu (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getHandleSaveMenu',
    method: 'POST',
    data:query
  })
}



export function getSpecialist (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getSpecialist',
    method: 'POST',
    data:query
  })
}

export function getSingleGrageDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getSingleGrageDetail',
    method: 'POST',
    data:query
  })
}


export function getGradeListDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getGradeListDetail',
    method: 'POST',
    data:query
  })
}


export function sendGradeTopics (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/sendGradeTopics',
    method: 'POST',
    data:query
  })
}


export function getDishSelectData (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getDishSelectData',
    method: 'POST',
    data:query
  })
}

export function getDishList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getDishList',
    method: 'POST',
    data:query
  })
}


export function getFoodList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getFoodList',
    method: 'POST',
    data:query
  })
}

export function getTopicsList (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getTopicsList',
    method: 'POST',
    data:query
  })
}


export function getTopicsDetail (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/getTopicsDetail',
    method: 'POST',
    data:query
  })
}

export function loginOut (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/loginOut',
    method: 'POST',
    data:query
  })
}



export function checkLogin (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/checkLogin',
    method: 'POST',
    data:query
  })
}


export function delTopicesRow (query) {
  return request({
    //url: 'https://www.baibaodu.com/index/UserData',
    url:requestUrl.baseUrl + '/kindergarten/index/delTopicesRow',
    method: 'POST',
    data:query
  })
}